import React from 'react';
import Paper from '@mui/material/Paper';
import { Footer } from "../components/Footer"
import { ResponsiveAppBarLight } from "./header-light"

export function PrivacyPolicy() {
  return (
    <React.Fragment>
      <>
      <ResponsiveAppBarLight/>
      <div className="PrivacyPolicy">
        <Paper className="text">
          <h2>Polityka Prywatności</h2>
          <p>Skoro trafiłeś do tego dokumentu, to znaczy, że ważne jest dla Ciebie bezpieczeństwo Twoich danych. Wiedz, że dbamy o odpowiednie 
            traktowanie Twoich danych osobowych. Szanując Twój czas, przygotowaliśmy dla Ciebie skróconą wersję najważniejszych zasad związanych 
            z ochroną prywatności. Jeżeli podstawowe informacje okażą się dla Ciebie niewystarczające, poniżej znajdziesz szerszy opis, 
            w którym wyjaśniamy jak obchodzimy się z powierzonymi przez Ciebie danymi osobowymi.
          </p>
          <h3>I. Podstawowe informacje</h3>
          <ol>
            <li>Administratorem danych osobowych jest AStep_Lab Anna Stępień.</li>
            <li>Dane przetwarzamy w zgodzie z Ogólnym Rozporządzeniem o Ochronie Danych 2016/679 („RODO”);</li>
            <li>Pamiętaj, że w przeważającej części udostępnienie danych osobowych, w tym także zgoda na ich przetwarzanie są całkowicie 
              dobrowolne, lecz niezbędne abyśmy mogli zrealizować cel dla jakiego je się udostępnia. Wszelkie przekazane dane osobowe są 
              przetwarzane wyłącznie w zakresie i w celu, na jaki je udostępniono.</li>
            <li>W każdej chwili masz prawo do aktualizacji swoich danych osobowych.</li>
            <li>Dbamy o należytą ochronę Twoich danych osobowych, gwarantując Ci, że będą one przetwarzane wyłącznie w celu, na który je 
              udostępniono, przechowywane w bezpieczny sposób oraz nieudostępniane podmiotom nieuprawnionym.</li>
            <li>Kontaktując się z nami lub dokonując zamówienia, przekazujesz swoje dane osobowe, a my gwarantujemy Ci, że Twoje dane pozostaną 
              bezpieczne.</li>
            <li>Podobnie jak ma to miejsce niemal na każdej innej stronie internetowej, my również wykorzystujemy pliki cookies.</li>
          </ol>
          <h3>II. Administrator</h3>
          <p>Administratorem danych osobowych, odpowiedzialnym za ich bezpieczeństwo jest:</p>
          <div className="admins-data">
            <p><span>Firma: </span>AStep_Lab Anna Stępień</p>
            <p><span>Adres: </span>ul. Dąbska 18K/80, 31-572 Kraków</p>
            <p><span>NIP: </span>6631713445</p>
            <p><span>REGON: </span>260414237</p>
            <p><span>E-mail: </span><a href="mailto:anna.stepien@asteplab.pl">anna.stepien@asteplab.pl</a></p>
          </div>
            <p>Kontakt jest też możliwy za pośrednictwem formularza zamieszczonego na stronie: <a href="https://asteplab.pl" target="_blank" rel="noopener noreferrer">asteplab.pl</a></p>
          <h3>III. Twoje uprawnienia</h3>
          <p>Aktualne przepisy prawa przyznają Ci szereg uprawnień związanych z przetwarzaniem Twoich danych osobowych:</p>
          <ol>
            <li>Dostęp do danych osobowych</li>
              <p>Masz prawo dostępu do Twoich danych, które przechowujemy jako administrator. Prawo to możesz wykonać poprzez zwrócenie się 
                do nas na podane dane kontaktowe.</p>          
            <li>Zmiana danych osobowych, ich sprostowanie, lub usunięcie</li>
              <p>Zmiany, w tym zaktualizowanie, sprostowanie lub usunięcie Twoich danych osobowych które przetwarzamy możesz dokonać poprzez 
                zwrócenie się do nas na podane dane kontaktowe. Z prawa do usunięcia danych możesz skorzystać gdy Twoje dane nie będą już 
                niezbędne do celów, dla których zostały zebrane przez nas albo gdy wycofasz swoją zgodę na przetwarzanie danych.</p>            
            <li>Wycofanie zgody</li>
              <p>W przypadku przetwarzania danych osobowych na podstawie zgody, możesz w dowolnej chwili wycofać tę zgodę. Informujemy o tym 
                prawie w każdym momencie zbierania zgód i umożliwiamy wycofanie zgody w tak łatwy sposób, jak jej udzielono. 
                Celem cofnięcia zgody, prosimy o zwrócenie się do nas na podane dane kontaktowe.</p>           
            <li>Prawo do ograniczenia przetwarzania lub wniesienia sprzeciwu wobec przetwarzania danych osobowych</li>
              <p>Masz prawo ograniczenia przetwarzania lub wniesienia sprzeciwu wobec przetwarzania Twoich danych osobowych w dowolnej chwili, 
                ze względu na szczególną sytuację, chyba że przetwarzanie jest wymagane zgodnie z przepisami prawa.</p>
            <li style={{marginBottom: "16px"}}>Możesz wnieść sprzeciw wobec przetwarzania Twoich danych osobowych, gdy:</li>
              <ul>
                <li>przetwarzanie danych osobowych odbywa się na podstawie prawnie uzasadnionego interesu lub dla celów statystycznych, a sprzeciw 
                  jest uzasadniony przez szczególną sytuację;</li>
                <li>dane osobowe przetwarzane są na potrzeby marketingu bezpośredniego, w tym są profilowane dla tego celu.</li>
              </ul>
            <li style={{marginBottom: "16px", marginTop: "16px"}}>Z kolei w odniesieniu do żądania ograniczenia przetwarzania danych informujemy, że przysługuje ono gdy:</li>
            <ul>
              <li>kwestionujesz prawidłowość danych osobowych – na okres pozwalający sprawdzić nam prawidłowość tych danych; </li>
              <li>przetwarzanie jest niezgodne z prawem, oraz sprzeciwiasz się usunięciu danych osobowych, żądając w zamian ograniczenia ich 
                wykorzystywania; </li>
              <li>nie potrzebujemy już danych osobowych do celów przetwarzania, ale są one potrzebne Tobie, do ustalenia, dochodzenia lub obrony 
                roszczeń; </li>
              <li>wniosłeś sprzeciw na mocy art. 21 ust. 1 RODO wobec przetwarzania danych przez nas do czasu stwierdzenia, czy prawnie 
                uzasadnione podstawy po naszej stronie są nadrzędne wobec Twoich podstaw sprzeciwu. </li>
            </ul>
            <li style={{marginTop: "16px"}}>Prawo do przenoszenia danych</li>
            <p>Masz prawo do przeniesienie dotyczących Ciebie danych oraz masz prawo przesłania tych danych za naszym pośrednictwem innemu 
              administratorowi, jeżeli: </p>
            <ul>
              <li>przetwarzanie odbywa się na podstawie zgody, w myśl art. 6 ust. 1 lit. a RODO lub art. 9 ust. 2 lit. a RODO; lub </li>
              <li>przetwarzanie odbywa się na podstawie umowy, w myśl art. 6 ust. 1 lit. b RODO; oraz </li>
              <li>przetwarzanie odbywa się w sposób zautomatyzowany.</li>
            </ul>
            <p>Wykonując prawo do przenoszenia danych możesz żądać, by dane osobowe zostały przesłane przez nas bezpośrednio innemu 
              administratorowi, o ile jest to technicznie możliwe. Prawo do przenoszenia danych nie może niekorzystnie wpływać na prawa i 
              wolności innych osób. W przypadku chęci realizacji tych praw, należy zwrócić się do nas na podane dane kontaktowe.</p>
            <li>Wszelkie pozostałe pytania, wątpliwości i skargi</li>
            <p>W razie, gdyby pojawiły się jakiekolwiek pytania, zastrzeżenia lub wątpliwości dotyczące treści niniejszej Polityki 
              Prywatności lub sposobu w jaki przetwarzane są dane osobowe, jak również skargi dotyczące tych kwestii, prosimy o 
              zwrócenie się do nas na podane dane kontaktowe wraz ze szczegółowymi informacjami dotyczącymi skargi. Wszelkie otrzymane 
              skargi zostaną rozpatrzone i udzielone zostaną na nie odpowiedzi. </p>
              <p>Masz również prawo wnieść skargę do organu nadzorczego, 
              którym jest Prezes Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa. Pamiętaj, że zawsze możesz również zwrócić się 
              do nas z żądaniem udostępnienia Ci szerszych informacji, jakimi danymi na Twój temat dysponujemy oraz w jakich celach je 
              przetwarzamy. Wystarczy, że wyślesz wiadomość na podany powyżej adres e-mail.</p>    
          </ol>
          <h3>IV. Cele, w jakich przetwarzamy Twoje dane osobowe</h3>
          <ol>
            <li>
              Zamówienia i wystawianie dokumentów rozliczeniowych
              <p>Zamawiając od nas produkt lub usługę, podajesz dane niezbędne do realizacji zamówienia (m.in. dane identyfikujące co do 
                tożsamości, czy dane kontaktowe). Podanie danych jest dobrowolne, ale niezbędne do złożenia zamówienia. Dane przekazane 
                w związku z zamówieniem przetwarzane są w celu realizacji zamówienia, wystawienia dokumentów rozliczeniowych, zapewnienia 
                kontaktu, a także mogą być wykorzystane w celach archiwalnych i statystycznych. Dane będą przetwarzane przez czas niezbędny 
                do realizacji zamówienia, a później do czasu upływu terminu przedawnienia roszczeń z tytułu zawartej umowy. Ponadto mamy 
                obowiązek przechowywać dokumenty rozliczeniowe z danymi przez okres 5 lat od końca roku podatkowego, w którym nastąpiło 
                wystawienie wspomnianego dokumentu. W przypadku danych podanych w celu realizacji zamówienia nie możesz sprzeciwić się 
                przetwarzaniu danych oraz domagać się usunięcia danych do czasu upływu terminu przedawnienia roszczeń z tytułu zawartej umowy. </p>
                <p>Dane osobowe są przetwarzane na podstawie kilku podstaw prawnych wyrażonych w ramach art. 6 RODO, w tym na potrzebę 
                wykonania umowy (dostarczenie przedmiotu umowy, prowadzenie komunikacji) lub podjęcia działań przed zawarciem umowy tj. 
                na podstawie art. 6 ust. 1 lit. b RODO; z uwagi na niezbędność wypełnienia obowiązku prawnego ciążącego na administratorze 
                (rozliczenie transakcji, wystawienie dokumentu rozrachunkowego) tj. na podstawie art. 6 ust. 1 lit. c RODO; z uwagi na prawnie 
                uzasadniony interes realizowany przez administratora (obrona przed roszczeniami, archiwizacja, marketing bezpośredni) tj. na 
                podstawie art. 6 ust. 1 lit. f RODO.</p>
            </li>
            <li>Kontakt bezpośredni lub z wykorzystaniem formularza
              <p>Kontaktując się z nami, udostępniasz co  najmniej te dane które są obowiązkowe. To od Ciebie zależy, czy w treści kierowanej 
                do nas wiadomości podasz dodatkowe dane. Podanie danych jest dobrowolne, lecz konieczne, by nawiązać kontakt i udzielić 
                odpowiedzi. Twoje dane są w tym przypadku przetwarzane w celu kontaktu z Tobą. Podstawą, do przetwarzania danych jest Twoja 
                zgoda, którą możesz w każdej chwili wycofać.</p></li>
            <li>Media społecznościowe i odesłania do innych twórców</li>
              <p>Na naszej stronie internetowej możesz naleźć odesłania do portali społecznościowych, gdzie zamieszczone są informacje o nas 
                i naszej działalności. Administratorem danych w ramach portalu społecznościowego tj. Twoich danych osobowych udostępnionych 
                w ramach Twojego indywidualnego konta jesteśmy zarówno my jako właściciel konta społecznościowego jak i właściciel portalu 
                społecznościowego. </p>
                <p>Dane osobowe są przetwarzane na podstawie zgody tj. na podstawie art. 6 ust. 1 lit. a RODO wyrażonej 
                poprzez Twoje działanie na naszym profilu (np. napisanie wiadomości, opublikowanie postu na naszym profilu, zostawienie 
                komentarza, wystawienie oceny na profilu). Zgoda ma charakter dobrowolny i swobodny i może być w każdej chwili wycofana. 
                Realizacja Twoich praw związanych z przetwarzaniem danych osobowych następować będzie bezpośrednio u właściciela portalu 
                społecznościowego.</p>
                <li>Zintegrowanie strony z wtyczkami
              <p>W ramach strony internetowej wykorzystujemy wtyczki służące głównie realizacji zadań marketingu, w tym marketingu 
                bezpośredniego. Dzięki temu zapewniamy możliwość wyświetlania odpowiednich treści w witrynie i trafienia z naszymi 
                treściami do osób zainteresowanych. Spośród narzędzi wykorzystujemy produkty dostarczane przez Google czy Meta – lista 
                dostępna w pkt V. O właściwościach poszczególnych narzędzi oraz sposobie wykorzystywania Twoich danych w pełny sposób dowiesz 
                się bezpośrednio na stronie dostawcy ww. narzędzi.</p>
            </li>
            <li>Opinie
              <p>Dajemy Ci możliwość wyrażenia swojej opinii na nasz temat i świadczonych przez nas usług, którą o ile wyrazisz na to zgodę 
                udostępnimy na naszej stronie internetowej, z przywołaniem Twoich danych osobowych. Dane osobowe są przetwarzane na podstawie 
                zgody tj. na podstawie art. 6 ust. 1 lit. a RODO. Zgoda ma charakter dobrowolny i swobodny i może być w każdej chwili wycofana.</p>
            </li>
            <li>Newsletter oraz darmowe materiały, zapis na listę oczekujących lub udział w kursie
              <p>Zapisując się do newslettera lub na darmowe materiały, czy kurs, przekazujesz nam swoje podstawowe dane za pośrednictwem 
                formularza zapisu. Podanie danych jest dobrowolne, lecz konieczne, by zapisać się do newslettera lub pobrać darmowe materiały, 
                czy wziąć udział w wydarzeniu. Dane te wykorzystywane są w celu przesyłania Ci informacji z życia naszej firmy, zaproszeń na 
                wydarzenia, przesyłanie propozycji handlowych, a podstawą prawną do ich przetwarzania jest Twoja zgoda. </p>
                <p>Dane będą przetwarzane przez czas funkcjonowania newslettera, chyba że wcześniej zrezygnujesz z jego otrzymywania, co spowoduje usunięcie Twoich danych 
                z bazy. W każdej chwili możesz sprostować swoje dane zapisane w bazie newsletterowej, jak również zażądać ich usunięcia, 
                rezygnując z otrzymywania newslettera. Dane osobowe są przetwarzane na podstawie zgody tj. na podstawie art. 6 ust. 1 lit. a RODO. 
                Zgoda ma charakter dobrowolny i swobodny i może być w każdej chwili wycofana.</p>
            </li>
          </ol>
          <h3>V. Powierzenie przetwarzania danych osobowych oraz udostępnienie</h3>
          <p>Twoje dane mogą być przetwarzane przez podmioty trzecie, którym zlecamy wykonanie poszczególnych czynności związanych 
            z zapewnieniem właściwego funkcjonowania naszej działalności. Podmioty, którym zlecamy przetwarzanie danych osobowych, 
            gwarantują stosowanie odpowiednich środków ochrony i bezpieczeństwa danych osobowych wymaganych przez przepisy prawa. 
            Podmioty, którym powierzamy przetwarzanie danych, to:</p>
          <ol>
            <li>Firmy obsługujące nasz obszar informatyczny;</li>
            <li>Firma obsługująca sprawy księgowe i rachunkowe;</li>
            <li>Firmy odpowiedzialne za marketing;</li>
            <li>Pozostałe firmy z którymi zawarliśmy umowę powierzenia przetwarzania danych osobowych.</li>
          </ol>
          <p>Dane udostępniane są również do:</p>
          <ol>
            <li>Firm obsługujących sprawy prawne;</li>
            <li>Firm obsługujących transport i przekaz pocztowy.</li>
          </ol>
          <p>Na ten moment Twój ruch na naszej stronie <a href="https://www.asteplab.pl" target="_blank" rel="noopener noreferrer">asteplab.pl</a> oraz Twoje ustawienia przeglądarki spowodować mogą dostęp do Twoich danych do 
            następujących dostawców podpiętych w ramach naszej strony:</p>
          <ul>
            <li>EmailJS Pte. Ltd</li>
            <li>Facebook (Meta)</li>
            <li>Google</li>
            <li>Vimeo</li>
          </ul>
          <h3>VI. Okres przetwarzania danych</h3>
          <p>Czas, przez jaki będziemy przetwarzać dane osobowe, jest uzależniony od podstawy prawnej ich przetwarzania. Informujemy, że:</p>
          <ol>
            <li>w przypadku, gdy przetwarzamy dane osobowe na podstawie zgody (art. 6 ust. 1 lit. a RODO), okres przetwarzania trwa do momentu 
              osiągnięcia zamierzonego celu lub wycofania zgody;</li>
            <li>w przypadku, gdy  przetwarzamy dane osobowe dla celów wykonania umowy lub podjęcia czynności przed wykonaniem umowy 
              (art. 6 ust. 1 lit. b RODO), przez okres świadczenia usług, a po jego zakończeniu przez okres przedawnienia roszczeń i archiwizacji 
              dokumentacji księgowej (5 lat), zgodnie z obowiązującymi przepisami prawa;</li>
            <li>w przypadku, gdy  przetwarzamy dane osobowe na podstawie uzasadnionego interesu (art. 6 ust. 1 lit. f RODO), okres przetwarzania 
              trwa do momentu ustania ww. interesu (m.in. okres przedawnienia roszczeń cywilnoprawnych) lub do momentu sprzeciwienia się 
              dalszemu takiemu przetwarzaniu – w sytuacjach, gdy sprzeciw taki zgodnie z przepisami prawa przysługuje;</li>
            <li>w przypadku, gdy przetwarzamy dane osobowe, ponieważ jest to konieczne z uwagi na obowiązujące przepisy prawa 
              (art. 6 ust. 1 lit. c RODO), okresy przetwarzania danych w tym celu określają te przepisy.</li>
          </ol>
          <h3>VII. Logi serwera</h3>
          <p>Korzystając z naszej strony internetowej przesyłasz z wykorzystaniem swojego urządzenia zapytania do serwera, na którym 
            przechowywana jest strona. Logi obejmują m.in. adres IP, datę i czas serwera, informacje o przeglądarce internetowej i systemie 
            operacyjnym, które używasz. Logi są zapisywane i przechowywane na serwerze. Logi serwera stanowią wyłącznie materiał pomocniczy 
            służący do administrowania stroną. Ich zawartość nie jest nikomu ujawniana. Dane zapisane w logach serwera nie są kojarzone 
            z konkretnymi osobami korzystającymi ze strony i nie są wykorzystywane w celu identyfikacji konkretnych osób.</p>
          <h3>VIII. Pliki cookies</h3>
          <ol>
            <li style={{marginBottom: "16px"}}>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, które przechowywane są w Twoim urządzeniu końcowym. Na stronie 
              internetowej stosujemy dwa zasadnicze rodzaje plików cookies: sesyjne oraz stałe. Cookies sesyjne są plikami tymczasowymi, 
              które przechowywane są w urządzeniu końcowym użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia 
              oprogramowania (przeglądarki internetowej). Stałe pliki cookies przechowywane są w Twoim urządzeniu końcowym przez czas określony 
              w parametrach plików cookies lub do czasu ich usunięcia przez Ciebie.</li>
            <li style={{marginBottom: "16px"}}>Ustawienia dotyczące plików cookies mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę 
              plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w Twoim urządzeniu. 
              Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania przeglądarki 
              internetowej. Pamiętaj, że wyłączenie lub ograniczenie obsługi plików cookies może powodować trudności w korzystaniu ze stron.</li>
            <li style={{marginBottom: "16px", marginTop: "16px"}}>Wykorzystujemy pliki cookies własne w celu:</li>
            <ul>
              <li>zapewnienia prawidłowego działania strony,</li>
              <li>prawidłowego działania formularzy.</li>
            </ul>
            <li style={{marginBottom: "16px", marginTop: "16px"}}>Wykorzystujemy również cookies podmiotów zewnętrznych:</li>
            <ul>
              <li>Google Analytics – w celu tworzenia statystyk i ich analizy dla optymalizacji strony,</li>
              <li>Google Adwords – w celu promocji strony internetowej,</li>
              <li>Pixel Meta – w celu reklamowania strony internetowej w serwisie Facebook,</li>
              <li>Innych wskazanych w pkt V powyżej.</li>
            </ul>         
          <li style={{marginTop: "16px"}}>Zarządzanie plikami cookies</li>
<p>Istnieją różne sposoby zarządzania preferencjami związanymi z cookies oraz udzielania i cofania zgody, w stosownych przypadkach:</p>
<ul>
  <li>Użytkownicy mogą zarządzać preferencjami związanymi z cookies bezpośrednio w ustawieniach własnego urządzenia, na przykład, zapobiegając 
    używaniu lub przechowywaniu cookies.</li>
  <li>Dodatkowo, ilekroć korzystanie z cookies odbywa się na podstawie zgody, użytkownicy mogą udzielić lub wycofać taką zgodę, ustawiając 
    swoje preferencje w ramach informacji o plikach cookie lub odpowiednio aktualizując takie preferencje za pośrednictwem odpowiedniego 
    widżetu dotyczącego preferencji w zakresie zgody, jeśli jest taki dostępny.</li>
  <li>Możliwe jest również, za pośrednictwem odpowiednich funkcji przeglądarki lub urządzenia, usunięcie wcześniej przechowywanych cookies, 
    w tym używanych do zapamiętania początkowej zgody użytkownika.</li>
</ul>
<p>Inne cookies znajdujące się w pamięci lokalnej przeglądarki mogą zostać usunięte poprzez usunięcie historii przeglądania.
W odniesieniu do wszelkich plików cookies osób trzecich, użytkownicy mogą zarządzać swoimi preferencjami i wycofać swoją zgodę za pomocą 
powiązanego linku rezygnacji (jeśli został taki udostępniony), za pomocą środków wskazanych w polityce prywatności osoby trzeciej lub 
kontaktując się z odnośną osobą trzecią.</p>
          <li>Wyłączenie reklamy opartej na zainteresowaniach</li>
          <p>Niezależnie od powyższego, użytkownicy mogą skorzystać z instrukcji dostarczonych przez YourOnlineChoices (UE), Network 
            Advertising Initiative (USA) oraz Digital Advertising Alliance (USA), DAAC (Kanada), DDAI (Japonia) lub inne podobne serwisy. 
            Takie inicjatywy umożliwiają użytkownikom wybór preferencji śledzenia dla większości narzędzi reklamowych. Operator zaleca zatem, 
            aby użytkownicy korzystali z tych zasobów, niezależnie od informacji zawartych w niniejszym dokumencie. Digital Advertising Alliance 
            oferuje aplikację o nazwie AppChoices, która pomaga użytkownikom kontrolować reklamy oparte na zainteresowaniach w aplikacjach 
            mobilnych.</p>
          <li>Konsekwencje odmowy wyrażenia zgody</li>
<p>Użytkownicy mogą swobodnie decydować o udzieleniu zgody lub nie. Należy jednak pamiętać, że cookies pomagają serwisowi / stronie 
  internetowej zapewnić użytkownikom lepsze doświadczenia i zaawansowane funkcjonalności (zgodnie z celami określonymi w niniejszym dokumencie). 
  W związku z tym, w przypadku braku zgody użytkownika, operator może nie być w stanie zapewnić odnośnych funkcji.</p>
</ol>
          <h3>IX. Zmiana polityki prywatności</h3>
          <p>Zastrzegamy sobie prawo do wprowadzania zmian w polityce prywatności. Zmiany będą wprowadzane jeśli będzie wymagać tego 
            obowiązujące prawo lub ulegną zmianie warunki technologiczne funkcjonowania strony internetowej. Aktualny tekst 
            polityki prywatności zawsze będzie znajdować się na stronie internetowej.</p>
        </Paper>
      </div>
<Footer/>
      </>
    </React.Fragment>
  );
}
