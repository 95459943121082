import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export default function RowAndColumnSpacing() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleRedirectToHome = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  const handleRedirectToRegulamin = () => {
    navigate('/regulamin');
    window.scrollTo(0, 0);
  };

  const handleRedirectToPolityka = () => {
    navigate('/polityka-prywatnosci');
    window.scrollTo(0, 0);
  };

  const renderLinkText = () => {
    if (location.pathname === '/') {
      return (
        <>
          <button onClick={handleRedirectToPolityka}>
            Polityka Prywatności
          </button>
          <button onClick={handleRedirectToRegulamin} >
            Regulamin
          </button>
        </>
      );
    }
    if (location.pathname === '/regulamin') {
      return (
        <>
          <button onClick={handleRedirectToHome}>
            Strona Główna
          </button>
          <button onClick={handleRedirectToPolityka}>
            Polityka Prywatności
          </button>
        </>
      );
    }
    if (location.pathname === '/polityka-prywatnosci') {
      return (
        <>
          <button onClick={handleRedirectToHome}>
            Strona Główna
          </button>
          <button onClick={handleRedirectToRegulamin}>
            Regulamin
          </button>
        </>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid 
        container 
        rowSpacing={2} 
        columnSpacing={{ xs: 1, sm: 2, md: 3 }} 
        sx={{ 
          '@media (max-width: 1020px)': {
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }}
      >
        <Grid item xs={12} md={4}>
          <Box className="references-footer" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            {renderLinkText()}
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box className="logo-footer" sx={{ textAlign: 'center' }}>
            <img 
              src={process.env.PUBLIC_URL + '/assets/logo.svg'} 
              alt="AStep_Lab Logo" 
              style={{ height: '50px', marginBottom: '10px', cursor: 'pointer' }}
              onClick={handleRedirectToHome}
            />
            <p>© 2025 AStep_Lab <span>| Wszystkie prawa zastrzeżone</span></p>
          </Box>
        </Grid>

        <Grid item xs={12} md={4} sx={{ '@media (max-width: 1020px)': { order: -1 } }}>
          <Box className="social-media" sx={{ textAlign: { xs: 'center', md: 'right' } }}>
            <Link href="https://www.facebook.com/profile.php?id=61564871384593" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export function Footer() {
  return (
    <React.Fragment>
      <Box className="Footer">
        <RowAndColumnSpacing />  
      </Box>
    </React.Fragment>
  );
}
